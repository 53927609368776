import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import loadable from "@loadable/component"

import SEO from "../components/seo"
import { setLocation } from "../actions/setLocation"
import SuspenseLoading from "../components/SuspenseLoading"

const AboutUsPage = loadable(
  () => import("../components/AboutUs/AboutUsPage"),
  { fallback: <SuspenseLoading /> }
)

const AboutUs = () => {
  const dispatch = useDispatch()

  //Set the site location.
  useEffect(() => {
    dispatch(setLocation("/about-us"))
    return
  }, [dispatch])

  return (
    <>
      <SEO title="About Us" />

      <AboutUsPage />
    </>
  )
}

export default AboutUs
